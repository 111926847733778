/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { ProjectListing, PostListing, CoolLink, Wrapper, DefaultHero, Title, SEO } from '../components'
import website from '../../config/website'
import { LocaleContext } from '../components/Layout'
import LocalizedLink from '../components/LocalizedLink'

const Headline = styled.p`
  font-size: 1.25rem;
  a {
    font-style: italic;
    font-weight: normal;
    color: ${props => props.theme.colors.bg};
    font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  a::after {
    top: 90%;
    background-color: ${props => props.theme.colors.bg};
  }
  margin-left: 0.5em;
`
const HeadWrapper = styled(Wrapper)`
  display: flex;
  align-items: baseline;
`
const CatWrapper = Wrapper.withComponent('main')

const Category = ({
  pageContext: { uid, locale },
  data: { prismicCategory, posts, projects },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO
        title={`${i18n.pages.categories.category}: ${prismicCategory.data.name} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        pageType={i18n.pages.categories.name}
      />
      <DefaultHero>
        <HeadWrapper>
          <h1>{prismicCategory.data.name}</h1><Headline><CoolLink><LocalizedLink to={`/${i18n.pages.categories.path}`}>{i18n.pages.categories.title}</LocalizedLink></CoolLink></Headline>
        </HeadWrapper>
      </DefaultHero>
      <CatWrapper id={website.skipNavId}>
        {!!projects.totalCount && 
        <Title style={{ marginTop: '2rem' }}>
          {projects.totalCount} {projects.totalCount === 1 ? 'Project' : 'Projects'}
        </Title>
        }
        <ProjectListing projects={projects.edges} />
        {!!posts.totalCount && 
        <Title style={{ marginTop: '2rem' }}>
          {posts.totalCount} {posts.totalCount === 1 ? 'Post' : 'Posts'}
        </Title>}
        <PostListing posts={posts.edges}/>
      </CatWrapper>
    </>
  )
}

export default Category

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    prismicCategory: PropTypes.object.isRequired,
    posts: PropTypes.object.isRequired,
    projects: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query CategoryPage($uid: String!, $locale: String!) {
    prismicCategory(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        name
      }
    }	
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          categories: { elemMatch: { category: { document: { elemMatch: { uid: { eq: $uid } } } } } }
        }
        lang: { eq: $locale }
      }
    ) {
      totalCount
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allPrismicProject(
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          categories: { elemMatch: { category: { document: { elemMatch: { uid: { eq: $uid } } } } } }
        }
        lang: { eq: $locale }
      }
    ) {
      totalCount
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            categories {
              category {
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
